import React, { useEffect, useState, useMemo } from "react";
import "./RFPLoader.scss";
import "./Dashboard.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { TextField, Grid, Box, Typography, Button, MenuItem, FormControl, InputLabel, Select, FormHelperText } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { api, api_external } from "../store";
import env from "../config";
import FileUploader from "../Components/FileUploader";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import JSZip from 'jszip';
import { useSelector } from "react-redux";
const USStates = ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida',
  'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland',
  'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire',
  'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania',
  'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington',
  'West Virginia', 'Wisconsin', 'Wyoming'];

const countries = ["USA"];
const rfpmartCategories = require('../Components/rfp/RFPMart_Categories.json');

const CreateOpportunity = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [dueDate, setDueDate] = useState(null);
  const [publicationDate, setPublicationDate] = useState(null);
  const [country, setCountry] = useState("USA");
  const [state, setState] = useState("");
  const [category, setCategory] = useState("");
  const [errors, setErrors] = useState({});
  const [filesAndStatus, setFilesAndStatus] = useState([]);
  const [uploadErrorMessages, setUploadErrorMessages] = useState([]);
  const [uploadError, setUploadError] = useState(false);
  const [fileNumberExceeded, setFileNumberExceeded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const user = useSelector((state) => state.userStatus.user);


  const handleCancel = () => {
    navigate("/rfx-opportunities");
  };

  const updateFileUploaderStatus = (updatedFilesAndStatus) => {
    setUploadErrorMessages([]);
    setFilesAndStatus(updatedFilesAndStatus);
    setUploadError(false);
    setFileNumberExceeded(false);
  };

  const handleFileNumberExceeded = () => {
    setFileNumberExceeded(true);
  };

  const validateTags = () => {
    const filesWithoutTags = filesAndStatus.filter(file => !file.tag);
    return filesWithoutTags.length === 0;
  };

  async function uploadToGCP(file) {
    const formData = new FormData();
    formData.append('files', file);

    try {
      const response = await api.post("file/uploadToGCP", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data.gsutilUri;
    } catch (err) {
      console.error('Failed to upload to GCP:', err);
      throw err;
    }
  }

  async function createZipAndUpload(files) {
    const zip = new JSZip();

    files.forEach((fileData) => {
      zip.file(fileData.file.name, fileData.file);
    });

    const zipBlob = await zip.generateAsync({ type: 'blob' });
    const zipFile = new File([zipBlob], 'documents.zip', { type: 'application/zip' });

    return await uploadToGCP(zipFile);
  }

  const handleCreate = async () => {

    const newErrors = {};

    if (!title) {
      newErrors.title = "Title is required.";
    }

    if (!country) {
      newErrors.country = "Country is required.";
    }

    if (country === "USA" && !state) {
      newErrors.state = "State is required.";
    }

    if (!category) {
      newErrors.category = "Category is required.";
    }

    if (filesAndStatus.length === 0) {
      newErrors.filesAndStatus = "At least one file must be uploaded.";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setIsLoading(true);

    try {
      let gsutilUri;

      if (filesAndStatus.length === 1) {
        gsutilUri = await uploadToGCP(filesAndStatus[0].file);
      } else {
        gsutilUri = await createZipAndUpload(filesAndStatus);
      }

      const requestData = {
        org_id: user.organizationId,
        title: title.trim(),
        country: country,
        state: state,
        category: category,
        deadline: dueDate ? dueDate.toISOString().split('T')[0] : null,
        publication_date: publicationDate ? publicationDate.toISOString().split('T')[0] : null,
        document_link: gsutilUri,
      };

      try {
        const response = await api_external.post(`${env.salesPubAPI}/capture-user-opportunity`, requestData);
        const rfp_id = response.data.rfp_id;
        navigate(
          `/opportunities/${rfp_id}`,
          { state: { user } }
        );
      } catch (error) {
        setError(true);
      }


    } catch (error) {
      console.error("Error creating opportunity:", error);
      setUploadError(true);
      setUploadErrorMessages([`An error occurred: ${error.message}`]);
      setIsLoading(false);
    }
  };

  const renderTextField = (
    id,
    label,
    value,
    onChange,
    error,
    helperText,
    maxLength,
    showCounter = false
  ) => (
    <>
      <TextField
        id={id}
        label={label}
        variant="outlined"
        fullWidth
        value={value}
        onChange={onChange}
        error={!!error}
        helperText={helperText}
        inputProps={{ maxLength }}
        multiline={id === "project-description"}
        minRows={id === "project-description" ? 3 : 1}
      />
      {showCounter && (
        <Typography
          variant="caption"
          sx={{
            display: "block",
            textAlign: "right",
            color: value.length > maxLength ? "red" : "textSecondary",
          }}
        >
          {value.length}/{maxLength} characters
        </Typography>
      )}
    </>
  );

  const [progress, setProgress] = useState({
    create_opportunity: 0,
  });

  useEffect(() => {
    let interval;
    if (isLoading && !error) {
      const simulateProgress = (key, cap, incrementValue, intervalTime) => {
        interval = setInterval(() => {
          setProgress((prevProgress) => {
            const newProgress = prevProgress[key] + incrementValue;
            if (newProgress >= cap) {
              clearInterval(interval);
              return { ...prevProgress, [key]: cap };
            }
            return { ...prevProgress, [key]: newProgress };
          });
        }, intervalTime);
        return interval;
      };

      simulateProgress("create_opportunity", 96, 1, 800);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isLoading, error]);

  useEffect(() => {
    if (error) {
      setProgress(prevProgress => ({
        ...prevProgress,
        create_opportunity: 0
      }));
    }
  }, [error]);

  if (isLoading) {
    return (
      <div className="rfp-loader">

        <Typography variant="pageHeading" sx={{ mb: 2 }}> Adding opportunity</Typography>

        {error && (
          <Typography variant="body2" style={{ color: "red" }}>
            Error ocurred during analyzing RFX files. Please contact
            support@xpub.ai for more information.
          </Typography>
        )}
        <div className="rfp-loader__progress-container">
          <div className="rfp-loader__progress-bars">
            <div className="rfp-loader__progress-item">
              <div className="rfp-loader__progress-item-label">
                Analyzing opportunity
              </div>
              <div className="rfp-loader__progress-item-bar">
                <div
                  className="rfp-loader__progress-item-bar-fill"
                  style={{ width: `${progress.create_opportunity}%` }}
                >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }

  return (
    <Grid sx={{ p: 4, height: "100%" }} container>
      <Grid item xs={12} sm={8} md={6}>
        <Typography variant="pageHeading"> Add opportunity</Typography>
        <Box sx={{ mb: 2, mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ marginTop: "1rem" }}>
              {renderTextField(
                "title",
                "Title",
                title,
                (e) => {
                  setTitle(e.target.value);
                  setErrors({ ...errors, title: "" });
                },
                errors.title,
                errors.title,
                75
              )}
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth error={!!errors.category}>
                <InputLabel htmlFor="category-select">Category</InputLabel>
                <Select
                  id="category-select"
                  value={category}
                  label="Category"
                  onChange={(e) => setCategory(e.target.value)}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxWidth: '30%'
                      }
                    },
                  }}
                >
                  {rfpmartCategories.map((category) => (
                    <MenuItem
                      key={category.code}
                      value={category.code}
                      sx={{
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                        width: 'auto'
                      }}
                    >
                      {category.description}
                    </MenuItem>
                  ))}
                </Select>
                {errors.category && <FormHelperText>{errors.category}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                fullWidth
                label="Country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                error={!!errors.country}
                helperText={errors.country}
              >
                {countries.map((country) => (
                  <MenuItem key={country} value={country}>
                    {country}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth error={!!errors.state} disabled={country !== 'USA'}>
                <InputLabel id="state-select-label">State/Province</InputLabel>
                <Select
                  labelId="state-select-label"
                  id="state-select"
                  value={state}
                  label="State/Province"
                  onChange={(e) => setState(e.target.value)}
                >
                  {USStates.map((state) => (
                    <MenuItem key={state} value={state}>
                      {state}
                    </MenuItem>
                  ))}
                </Select>
                {errors.state && <FormHelperText>{errors.state}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={["DatePicker"]}
                  sx={{ paddingTop: "0.3rem" }}
                >
                  <DatePicker
                    label="Publication Date"
                    value={publicationDate}
                    onChange={(date) => {
                      setPublicationDate(date);
                      // If due date is before new publication date, reset it
                      if (dueDate && date && dueDate.isBefore(date)) {
                        setDueDate(null);
                      }
                    }}
                    sx={{ paddingTop: 0, width: '100%' }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={["DatePicker"]}
                  sx={{ paddingTop: "0.3rem" }}
                >
                  <DatePicker
                    label="Due Date"
                    value={dueDate}
                    onChange={(date) => setDueDate(date)}
                    minDate={publicationDate ? publicationDate.add(1, 'day') : dayjs()}
                    sx={{ paddingTop: 0, width: '100%' }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>


            <Grid item xs={12} sx={{ marginTop: "1rem" }}>
              <Typography variant="primaryText">Add RFX Files</Typography>
              <Typography
                variant="secondaryText"
                style={{ marginBottom: "1rem" }}
              >
                Accepted formats: .txt, .docx, .pdf | Maximum file size is 2GB.
              </Typography>
              <FileUploader
                uploadErrorMessages={uploadErrorMessages}
                filesAndStatus={filesAndStatus}
                updateFileUploaderStatus={updateFileUploaderStatus}
                maxFiles={100}
                uploadError={uploadError}
                allowAudioFiles={false}
                acceptTypes={[".txt", ".docx", ".pdf"]}
                displayTags={false}
                fileNumberExceeded={fileNumberExceeded}
                onFileNumberExceeded={handleFileNumberExceeded}
              />
              {errors.filesAndStatus && (
                <Typography color="error" variant="primaryText" gutterBottom>{errors.filesAndStatus}</Typography>
              )}
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "1rem",
            mt: 2,
            mb: 2,
          }}
        >
          <Button
            variant="secondary"
            onClick={handleCancel}
            sx={{
              textTransform: "none",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleCreate}
            sx={{
              textTransform: "none",
            }}
          >
            Add
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CreateOpportunity;
